import React from 'react'

const Loading = () => {
    return (
        <>
            <div id="splash-screen" className="splash-screen">
                {/* <img src="%PUBLIC_URL%/media/logos/default-small.svg" alt="Metronic logo" /> */}
                <span>Loading ...</span>
            </div>
        </>
    )
}

export default Loading