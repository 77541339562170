import React,{Suspense, useState, useEffect} from 'react'
import Loading from './components/Loading';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './assets/css/style.css'
import {Routes, Route} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { Search } from 'react-bootstrap-icons';
import { Link, useNavigate } from 'react-router-dom';

const Home = React.lazy(() => import('./pages/Home'));
const HowItWorks = React.lazy(() => import('./pages/HowItWorks'));
const Contact = React.lazy(() => import('./pages/Contact'));
const Faq = React.lazy(() => import('./pages/FAQs'));
const TestPage = React.lazy(() => import('./pages/TestPage'));
const AllCategories = React.lazy(() => import('./pages/AllCategories'));
const CategoryPage = React.lazy(() => import('./pages/CategoryPage'));
const Order = React.lazy(() => import('./pages/Order'));
const FindLab = React.lazy(() => import('./pages/FindLab'));
const SearchQuery = React.lazy(() => import('./pages/SearchQuery'));
const Thankyou = React.lazy(() => import('./pages/Thankyou'));
const App = () => {
    const testCart = localStorage.getItem('testCart');
    const [modalShow, setModalShow] = useState(false);
    const [categories, setCategories] = useState([]);
    const [searchQueryModal, setSearchQueryModal] = useState(''); 

    const [cartState, setCartState] = useState(testCart ? JSON.parse(testCart) : []);
    if(!testCart){
        localStorage.setItem('testCart', JSON.stringify([]));
    }


    useEffect(() => {
        // get tests and panels
        axios.get(`${process.env.REACT_APP_API_URL}/categories/website-categories`)
        .then(res => {
            setCategories(res.data.categories);
        })
        .catch(err => {
            console.log(err);
        })
    }, [])


    const addItemToCart = (item) => {
        const cart = JSON.parse(localStorage.getItem('testCart'));
        const itemExists = cart.find((cartItem) => cartItem.id === item.id);
        if(itemExists){
            const updatedCart = cart.map((cartItem) => {
                if(cartItem.id === item.id){
                    // return {
                        // ...cartItem,
                        // quantity: cartItem.quantity + 1
                        alert("Item already added to cart")
                    // }
                }
                
                return cartItem;

            })
            localStorage.setItem('testCart', JSON.stringify(updatedCart));
        }else{
            localStorage.setItem('testCart', JSON.stringify([...cart, {...item, quantity: 1}]));
            setCartState([...cart, {...item, quantity: 1}]);
        }
        // setCartState([...cart, {...item, quantity: 1}]);
    }

    const removeItemFromCart = (item) => {
        const cart = JSON.parse(localStorage.getItem('testCart'));
        const updatedCart = cart.filter((cartItem) => cartItem.id !== item.id);
        localStorage.setItem('testCart', JSON.stringify(updatedCart));
        setCartState(updatedCart);

    }

    const updateItemQuantity = (item, quantity) => {
        if(quantity < 1){
            return;
        }
        const cart = JSON.parse(localStorage.getItem('testCart'));
        const updatedCart = cart.map((cartItem) => {
            if(cartItem.id === item.id){
                return {
                    ...cartItem,
                    quantity: quantity
                }
            }
            return cartItem;
        })
        localStorage.setItem('testCart', JSON.stringify(updatedCart));
        setCartState(updatedCart);

    }

    const cartTotal = () => {
        const cart = JSON.parse(localStorage.getItem('testCart'));
        const total = cart.reduce((acc, item) => {
            return acc + (item.price * item.quantity);
        }, 0)
        return total;
    }

    const clearCart = () => {
        localStorage.setItem('testCart', JSON.stringify([]));
        setCartState([]);

    }

    const getCart = () => {
        const items = JSON.parse(localStorage.getItem('testCart'));
        if(items.length === 0){
            setCartState([]);
        }else{
            setCartState(items);
        }
    }

    // list for functions
    const functions = {
        addItemToCart,
        removeItemFromCart,
        updateItemQuantity,
        clearCart,
        getCart,
        cartTotal
    }
    const handleModalOpen = () => {
        setModalShow(true);
    }

    const handleModalClose = () => {
        setModalShow(false);
    }

    const modalFunctions = {
        handleModalClose,
        handleModalOpen
    }

    const navigate = useNavigate();
    const gotoCategory = (e, category) => {
        e.preventDefault();
        // categories
        setModalShow(false);
        navigate(`/categories/${category}`);
    }

    const searchHandler = (e) => {
        e.preventDefault();
        setModalShow(false);
        setSearchQueryModal('');
        if(searchQueryModal.length > 0){
            navigate(`/search/keyword?query=${searchQueryModal}`, { replace: true });
        }
        
    };

    return (
        <>
            <Suspense fallback={<Loading />}>
                <Routes>
                    <Route path="/" exact element={<Home cartOperation={functions} cartState={cartState} modalFunctions={modalFunctions} categories={categories}/>} />
                    <Route path="/how-it-works" element={<HowItWorks cartOperation={functions} cartState={cartState} modalFunctions={modalFunctions} />} categories={categories}/>
                    <Route path="/contact-us" element={<Contact cartOperation={functions} cartState={cartState} modalFunctions={modalFunctions} />} categories={categories}/>
                    <Route path="/faq" element={<Faq cartOperation={functions} cartState={cartState} modalFunctions={modalFunctions} />} categories={categories}/>
                    <Route path="/find-lab" element={<FindLab cartOperation={functions} cartState={cartState} modalFunctions={modalFunctions} categories={categories}/>} categories={categories}/>

                    {/* <Route path="/test-page" element={<TestPage />} /> */}
                    <Route path="/categories" element={<AllCategories cartOperation={functions} cartState={cartState} modalFunctions={modalFunctions} categories={categories}/>} />
                    <Route path="/categories/*" element={<CategoryPage cartOperation={functions} cartState={cartState} modalFunctions={modalFunctions} categories={categories}/>} />
                    <Route path="/test/*" element={<TestPage cartOperation={functions} cartState={cartState} modalFunctions={modalFunctions} categories={categories}/>} />
                    <Route path="/order" element={<Order cartOperation={functions} cartState={cartState} modalFunctions={modalFunctions} />} categories={categories}/>
                    <Route path="/thankyou" element={<Thankyou cartOperation={functions} cartState={cartState} modalFunctions={modalFunctions} categories={categories}/>} />
                    <Route path='/search/:query' element={<SearchQuery cartOperation={functions} cartState={cartState} modalFunctions={modalFunctions} categories={categories}/>} />

                </Routes>
                <ToastContainer 
                        position="bottom-left"
                        autoClose={5000}
                        />
            </Suspense>
            {/* Modal for find a test */}
            <Modal
                show={modalShow}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                top="true"
                onHide={handleModalClose}
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <span className='ps-4'>
                            Find Tests
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-light px-5'>
                    <div className='row'>
                        <div className="col-md-6">
                            {/* inline form */}
                                {/* <h4>Search</h4> */}
                                <form className="form-inline" onSubmit={searchHandler}>
                                    <label className="form-label fs-zk-17 fw-bold">Search:</label>
                                    <div className="input-group">
                                        <input className="form-control rounded-0 fs-zk-15 " type="text" placeholder="Enter term or test name" id="example-search-input" value={searchQueryModal} onChange={e=>setSearchQueryModal(e.target.value)}/>
                                        <span className="input-group-append"><button className="btn rounded-0 btn-zk-primary-light fs-zk-15" name="Search " type="button" aria-label="search" onClick={searchHandler}><Search /></button></span>
                                    </div>
                                </form>
                        </div>
                    </div>
                    <div className="fs-zk-18 fw-bold mt-4">
                        Or Choose a Test Category
                    </div>
                    <div className='row pt-md-3 pt-2 pb-3 popup-links'>
                        {categories?.map((category, index) => (
                            <div className='col-lg-3 col-6 mb-3 d-flex px-sm-4' key={index}>
                                <div onClick={e=>gotoCategory(e, category.slug)} className='w-100 d-block text-decoration-none text-zk-secondary fs-zk-16 lh-sm mb-1 hover-link-primary text-capitalize' style={{fontWeight:"500", cursor:"pointer"}}>{category.category_name}</div>
                            </div>
                        ))}
                    </div>
    

                </Modal.Body>
            </Modal>

        </>
    )
}

export default App